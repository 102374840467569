import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa';
import { Nav, Logo, Hamburger, Menu, MenuItem } from './style';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [navbarColor, setNavbarColor] = useState("");

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setNavbarColor("#222");
    } else {
      setNavbarColor("");
    }
  };

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();

    const scrollToSection = () => {
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
      setIsOpen(false);
    };

    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: targetId } });
    } else {
      scrollToSection();
    }
  };

  useEffect(() => {
    const { state } = location;
    if (state && state.scrollTo) {
      const targetId = state.scrollTo;
      setTimeout(() => {
        const targetSection = document.getElementById(targetId);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav bgColor={navbarColor}>
      <Logo to="/">Velance</Logo>
      <Hamburger onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </Hamburger>
      <Menu isOpen={isOpen}>
        <MenuItem onClick={(e) => handleSmoothScroll(e, "topo")}>Home</MenuItem>
        <MenuItem to="/conheca-a-agencia" onClick={toggleMenu}>Conheça a Velance</MenuItem>
        <MenuItem onClick={(e) => handleSmoothScroll(e, "servicos")}>Nossos serviços</MenuItem>
        <MenuItem to="/portfolios" onClick={toggleMenu}>Potfólio</MenuItem>
        <MenuItem onClick={(e) => handleSmoothScroll(e, "depoimentos")}>Veja o que nossos clientes dizem</MenuItem>
        <MenuItem to="/" onClick={toggleMenu}>Contato</MenuItem>
        <MenuItem to="/blog" onClick={toggleMenu}>Blog</MenuItem>
      </Menu>
    </Nav>
  );
};

export default Navbar;
