import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const Logo = styled(Link)`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
`;

const Hamburger = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Menu = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    gap: 2rem;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #222;
    width: 100%;
    padding: 1rem;
  }
`;

const MenuItem = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

export { Nav, Logo, Hamburger, Menu, MenuItem };
