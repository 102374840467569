import styled from "styled-components";
import imgBannerHome from "../../assets/imgs/banner-home.jpg";
import imgDivider from "../../assets/imgs/imagem-divider.png";

const ContentHome = styled.main`
  display: flex;
  flex-direction: column;
`;

const BannerHome = styled.section`
  background-image: url(${imgBannerHome});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .container-banner_home {
    color: #fff;
    margin: 0 auto;
    padding: 8em 0;
    text-align: center;
    width: 98%;
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 2em;
      font-weight: 400;
    }
  }
  @media (min-width: 768px) {
    background-attachment: fixed;
    background-size: 100%;
    height: 100svh;
    .container-banner_home {
      padding: 18em 0;
      width: 90%;
      h1 {
        font-size: 4em;
      }
      h2 {
        font-size: 3em;
      }
    }
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
`;

const SectionHome = styled.section`
  .container {
    margin: 0 auto;
    padding: 2em 0;
    width: 98%;
    @media (min-width: 768px) {
      padding: 5em 0 2em 0;
      width: 90%;
      h2 {
        font-size: 60px;
      }
      p {
        font-size: 33px;
        margin-top: 30px;
      }
    }
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const CardIcon = styled.div`
  background-color: #ffeb3b;
  border-radius: 50%;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  height: 80px;
  margin: 15px auto;
  width: 80px;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const CardTitle = styled.h3`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const CardText = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  @media (min-width: 768px) {
    font-size: 1.2em;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: stretch;
`;

export const CaseCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 98%;
  margin: 0 auto;
  overflow: hidden;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

export const CarouselItem = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  padding: 20px;
  text-align: center;
`;

export const TestimonialText = styled.p`
  font-style: italic;
  color: #333;
  margin-bottom: 15px;
`;

export const TestimonialAuthor = styled.h4`
  font-weight: bold;
  color: #555;
  margin-top: 10px;
`;

export const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  z-index: 10;

  &:hover {
    color: #555;
  }

  ${({ direction }) => (direction === "left" ? "left: 10px;" : "right: 10px;")}
`;

export const SectionDivider = styled.section`
  position: relative;
  height: 400px;
  background-image: url(${imgDivider});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 768px) {
    background-attachment: scroll;
  }
`;

export const ContentDivider = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
  }
  @media (min-width: 768px) {
    padding: 8em 0;
    p{
      font-size: 1.6em;
    }
  }
`;

export { BannerHome, ContentHome, Overlay, SectionHome };
