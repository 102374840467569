import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  width: 99%;
  @media(min-width: 768px) {
    width: 90%;
  }
`

const getColWidth = (value) => {
  if(!value) return;

  let width = value / 12 * 100;
  return `width: ${width}%`;
}

const Col = styled.div`
    padding: 0.5em;
    @media(min-width: 768px) {
      ${({size}) => size && getColWidth(size)}
    }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media(min-width: 768px) {
    flex-direction: row;
  }
`

export{
    Container,
    Col,
    Row
}