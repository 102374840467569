import React, { useState } from "react";
import {
  CarouselContainer,
  CarouselWrapper,
  CarouselItem,
  TestimonialText,
  TestimonialAuthor,
  NavButton,
} from "./style"; 
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CarouselDepoimentos = () => {
  const testimonials = [
    {
      quote: "A Velance transformou nossa presença online e trouxe resultados que superaram nossas expectativas.",
      author: "Porto Villares",
    },
    {
      quote: "Trabalhar com a Velance foi um divisor de águas para nosso negócio. Eles realmente entenderam nossas necessidades e criaram soluções incríveis.",
      author: "Projeto Plantar",
    },
    {
      quote: "Equipe dedicada e sempre pronta para nos ajudar. Um serviço que vale cada centavo investido!",
      author: "PodBH",
    },
    {
      quote: "Desde o design até a estratégia de marketing, a Velance nos guiou em todas as etapas. Impressionante!",
      author: "Empresário Entusiasmado",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
    setCurrentIndex(index);
  };

  const nextSlide = () => {
    const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(index);
  };

  return (
    <CarouselContainer>
      <NavButton onClick={prevSlide} direction="left">
        <FaChevronLeft />
      </NavButton>
      <CarouselWrapper
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((testimonial, index) => (
          <CarouselItem key={index}>
            <TestimonialText>"{testimonial.quote}"</TestimonialText>
            <TestimonialAuthor>- {testimonial.author}</TestimonialAuthor>
          </CarouselItem>
        ))}
      </CarouselWrapper>
      <NavButton onClick={nextSlide} direction="right">
        <FaChevronRight />
      </NavButton>
    </CarouselContainer>
  );
};

export default CarouselDepoimentos;
