import imgSobreNos from '../../assets/imgs/banner-sobre-nos.png'
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding-bottom: 3rem;
`;

export const Banner = styled.div`
  background-image: url(${imgSobreNos});
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  text-align: center;
  @media (min-width: 768px) {
    height: 70vh;
  }
`;

export const BannerText = styled.div`
  h1 {
    font-size: 4.5rem;
    font-weight: 800;
    margin: 0;
  }

  p {
    font-size: 1.8rem;
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.8rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 4rem auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: 768px) {
    gap: 5rem;
  }
`;

export const Section = styled.div`
  padding: 3rem;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 3rem;
    flex-direction: row;
    h2{
        font-size: 2.5rem;
    }
    p{
        font-size: 1.4rem;
    }
  }
`;

export const IconWrapper = styled.div`
  font-size: 4rem;
  color: #F7CF09;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    font-size: 4.5rem;
    margin-bottom: 0;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2, h3 {
    font-weight: 800;
    color: #333;
    text-align: center;
    line-height: 1.4;
  }

  h2 {
    font-size: 3.2rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 2.5rem;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.8;
    color: #555;
    text-align: center;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    text-align: left;
    
    h2 {
      font-size: 4rem;
    }

    h3 {
      font-size: 3rem;
    }

    p {
      font-size: 1.4rem;
    }
  }
`;

export const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Value = styled.div`
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    margin-top: 1.2rem;
    font-size: 2.5rem;
    color: #F7CF09;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #666;
  }

  @media (min-width: 768px) {
    h3 {
      font-size: 2.8rem;
    }
  }
`;

export const CallToAction = styled.div`
  padding: 3.5rem;
  background-color: #F7CF09;
  color: white;
  border-radius: 12px;
  margin-top: 4rem;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  p {
    font-size: 1.5rem;
    line-height: 1.6;

    a {
      color: #ffffff;
      font-weight: bold;
      text-decoration: underline;
      transition: color 0.3s ease;
    }

    a:hover {
      color: #333;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 768px) {
    padding: 4rem;
    p {
      font-size: 1.7rem;
    }
  }

  &:hover {
    background-color: #e0b807;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }
`;
