import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #222;
  color: #fff;
  padding: 40px 0 0 0;
  text-align: center;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 99%;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLogo = styled.div``;

export const FooterSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 200px;
  padding: 20px;

  h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  p,
  a {
    font-size: 14px;
    color: #fff;
    margin: 5px 0;
    text-decoration: none;
  }

  a:hover {
    color: #ddd;
  }

  @media (max-width: 767px) {
    padding: 15px;
    text-align: center;

    h4 {
      font-size: 16px;
    }

    p,
    a {
      font-size: 13px;
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  a {
    font-size: 20px;
    color: #fff;
    margin: 0 10px;
    transition: color 0.3s;

    &:hover {
      color: #ddd;
    }
  }

  @media (max-width: 767px) {
    a {
      font-size: 18px;
      margin: 0 8px;
    }
  }
`;

export const Copyright = styled.div`
  background-color: #111;
  padding: 15px 0;
  font-size: 14px;
  color: #aaa;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;
