import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Row } from "../../shared/Grids/style";
import {
  BannerHome,
  ContentHome,
  Overlay,
  SectionHome,
  Card,
  CardIcon,
  CardTitle,
  CardText,
  SectionDivider,
  ContentDivider,
} from "./style";
import { FaPalette, FaBullhorn, FaCode } from "react-icons/fa";
import imagemBemVindo from "../../assets/imgs/imagem-bem-vindo.png";
import imagemInovacao from "../../assets/imgs/inovacao.png";
import CarouselClientes from "./CarouselClientes";
import CarouselDepoimentos from "./CarouselDepoimentos";

import { clientLogos } from "./mockLogo";

const Home = () => {
  return (
    <ContentHome>
      {/* Banner de Boas-vindas */}
      <BannerHome id="topo">
        <Overlay>
          <div className="container-banner_home">
            <Row>
              <Col size={12}>
                <h1>Bem-vindo à Velance</h1>
                <h2>
                  Damos vida às suas ideias com estratégias digitais impactantes
                  que geram resultados reais e duradouros.
                </h2>
              </Col>
            </Row>
          </div>
        </Overlay>
      </BannerHome>

      {/* Seção de Apresentação */}
      <SectionHome>
        <div className="container">
          <Row>
            <Col size={6}>
              <h2>
                Somos a Velance: paixão por inovação e resultados
                extraordinários
              </h2>
              <p>
                Na Velance, cada marca é uma parceria valiosa. Conectamos sua
                empresa ao seu público de forma autêntica e estratégica,
                garantindo que sua mensagem se destaque no mundo digital.
              </p>
              <p>
                Combinando criatividade, inovação e tecnologia, oferecemos
                serviços que ajudam sua marca a crescer, desde a criação de
                sites até estratégias de marketing digital completas.
              </p>
            </Col>
            <Col size={6}>
              <LazyLoadImage
                alt="Imagem de boas-vindas ilustrativa da Velance"
                src={imagemBemVindo}
              />
            </Col>
          </Row>
        </div>
      </SectionHome>

      {/* Seção de Inovação */}
      <SectionHome>
        <div className="container">
          <Row>
            <Col size={6}>
              <h2>Inovação é o nosso DNA</h2>
              <p>
                Procurando inovação? A Velance é o parceiro certo! Respiramos
                tecnologia e criatividade para entregar soluções digitais que
                superam expectativas e criam experiências memoráveis para cada
                cliente.
              </p>
              <p>
                Priorizamos práticas e tecnologias que tornam nossos projetos
                acessíveis, inclusivos e responsivos, oferecendo a melhor
                experiência para todos os usuários, em qualquer dispositivo.
              </p>
            </Col>
            <Col size={6}>
              <LazyLoadImage
                alt="Imagem ilustrativa sobre inovação na Velance"
                src={imagemInovacao}
              />
            </Col>
          </Row>
        </div>
      </SectionHome>

      {/* Seção de Serviços */}
      <SectionHome id="servicos">
        <div className="container">
          <Row>
            <Col size={12}>
              <h2>Nossos Serviços</h2>
            </Col>
          </Row>
          <Row>
            <Col size={4}>
              <Card>
                <CardIcon>
                  <FaPalette />
                </CardIcon>
                <CardTitle>Serviços de Design</CardTitle>
                <CardText>
                  Designs que destacam sua marca e contam sua história.
                  Estratégia e estética unidas para resultados impressionantes.
                </CardText>
              </Card>
            </Col>
            <Col size={4}>
              <Card>
                <CardIcon>
                  <FaBullhorn />
                </CardIcon>
                <CardTitle>Marketing Digital</CardTitle>
                <CardText>
                  Estratégias personalizadas para alcançar e envolver seu
                  público, maximizando resultados e visibilidade.
                </CardText>
              </Card>
            </Col>
            <Col size={4}>
              <Card>
                <CardIcon>
                  <FaCode />
                </CardIcon>
                <CardTitle>Desenvolvimento Web</CardTitle>
                <CardText>
                  Sites rápidos, seguros e responsivos, projetados para oferecer
                  a melhor experiência em todas as plataformas.
                </CardText>
              </Card>
            </Col>
          </Row>
        </div>
      </SectionHome>

      <SectionDivider>
        <Overlay>
          <ContentDivider>
            <Row>
              <Col size={12}>
                <h1>Contrate hoje mesmo nossos serviços</h1>
                <p>
                  Se você precisa de um site personalizado, um design atraente
                  ou uma campanha de marketing digital, estamos prontos para
                  ajudar.
                </p>
              </Col>
            </Row>
          </ContentDivider>
        </Overlay>
      </SectionDivider>

      {/* Seção de Diferenciais */}
      <SectionHome>
        <div className="container">
          <Row>
            <Col size={12}>
              <h2>Por que Escolher a Velance?</h2>
              <p>
                A Velance é mais do que uma agência digital — somos seus
                parceiros na jornada de crescimento. Aqui estão alguns motivos
                para confiar em nossos serviços:
              </p>
            </Col>
            <Col size={4}>
              <Card>
                <CardTitle>Experiência Comprovada</CardTitle>
                <CardText>
                  Nossa equipe traz anos de experiência em diferentes setores, o
                  que nos permite entender profundamente as necessidades de cada
                  cliente.
                </CardText>
              </Card>
            </Col>
            <Col size={4}>
              <Card>
                <CardTitle>Soluções Personalizadas</CardTitle>
                <CardText>
                  Cada projeto é único. Criamos soluções sob medida para que sua
                  marca se destaque de forma autêntica e eficaz.
                </CardText>
              </Card>
            </Col>
            <Col size={4}>
              <Card>
                <CardTitle>Compromisso com Resultados</CardTitle>
                <CardText>
                  Medimos nosso sucesso pelos resultados que entregamos. Seu
                  crescimento é o nosso objetivo.
                </CardText>
              </Card>
            </Col>
          </Row>
        </div>
      </SectionHome>

      {/* Seção de Clientes */}
      <SectionHome>
        <div className="container">
          <Row>
            <Col size={12}>
              <h2>Nossos Clientes</h2>
              <p>
                Temos orgulho de colaborar com marcas renomadas que confiam na
                Velance para transformar suas ideias em realidade. Veja algumas
                de nossas parcerias:
              </p>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <CarouselClientes slides={clientLogos} />
            </Col>
          </Row>
        </div>
      </SectionHome>

      {/* Seção de Depoimentos */}
      <SectionHome id="depoimentos">
        <div className="container">
          <Row>
            <Col size={12}>
              <h2>Depoimentos</h2>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <CarouselDepoimentos />
            </Col>
          </Row>
        </div>
      </SectionHome>
    </ContentHome>
  );
};

export default Home;
