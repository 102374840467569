import React from 'react';
import { FaRocket, FaLightbulb, FaUsers, FaHandshake, FaGlobe, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container, Banner, BannerText, Content, Section, ValuesGrid, Value, IconWrapper, CallToAction } from './style';
const Velance = () => {
  return (
    <Container>
      <Banner>
        <BannerText>
          <h1>Conheça nossa agência</h1>
        </BannerText>
      </Banner>
      <Content>
        <Section>
          <h2>Sobre Nós</h2>
          <p>
            Na Velance, somos uma agência de marketing digital dedicada a impulsionar marcas para novos patamares. 
            Nossa paixão é criar soluções personalizadas e criativas que ajudam nossos clientes a se destacar no cenário digital. 
            Com uma equipe experiente e inovadora, nos comprometemos a entregar estratégias que realmente fazem a diferença, 
            garantindo um crescimento sustentável e resultados mensuráveis.
          </p>
        </Section>
        <Section>
          <h2>Missão</h2>
          <p>
            Nossa missão é ajudar empresas de todos os portes a alcançar seu potencial máximo no ambiente digital, 
            oferecendo soluções estratégicas e inovadoras que aumentem seu impacto e presença online. 
            Acreditamos que cada marca tem uma história única, e estamos aqui para contá-la de maneira autêntica e envolvente.
          </p>
        </Section>
        <Section>
          <h2>Valores</h2>
          <ValuesGrid>
            <Value>
              <IconWrapper><FaHandshake /></IconWrapper>
              <h3>Parceria</h3>
              <p>Trabalhamos lado a lado com nossos clientes, formando parcerias que se baseiam na confiança e no sucesso mútuo.</p>
            </Value>
            <Value>
              <IconWrapper><FaGlobe /></IconWrapper>
              <h3>Inovação</h3>
              <p>Estamos sempre em busca de novas ideias e tecnologias para oferecer soluções criativas e modernas.</p>
            </Value>
            <Value>
              <IconWrapper><FaChartLine /></IconWrapper>
              <h3>Resultados</h3>
              <p>Focamos em estratégias que geram resultados concretos e mensuráveis, proporcionando crescimento e visibilidade.</p>
            </Value>
          </ValuesGrid>
        </Section>
        <Section>
          <IconWrapper>
            <FaRocket />
          </IconWrapper>
          <h3>Estratégia de Crescimento</h3>
          <p>Desenvolvemos estratégias digitais focadas no crescimento contínuo e sustentável da sua marca.</p>
        </Section>
        <Section>
          <IconWrapper>
            <FaLightbulb />
          </IconWrapper>
          <h3>Criatividade</h3>
          <p>Nossa equipe de especialistas traz criatividade para cada projeto, encontrando maneiras únicas de destacar sua marca.</p>
        </Section>
        <Section>
          <IconWrapper>
            <FaUsers />
          </IconWrapper>
          <h3>Colaboração</h3>
          <p>Acreditamos no poder do trabalho em equipe. Sua visão, combinada com nossa expertise, resulta em soluções poderosas.</p>
        </Section>
        <CallToAction>
          <p>Interessado em saber mais? <Link to="/contato">Fale conosco e vamos crescer juntos!</Link></p>
        </CallToAction>
      </Content>
    </Container>
  );
};

export default Velance;
