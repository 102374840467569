import { Link } from 'react-router-dom'
import styled from "styled-components";
import { Container, Row, Col } from '../../shared/Grids/style'
import imgErro from "../../assets/imgs/erro-404.png";

const ContentErro = styled.section`
  background-color: #F7CF09;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  a{
    background-color: #222;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    margin-top: 20px;
    padding: 1rem 2rem;
  }
  @media(min-width: 768px){
    padding: 8rem 0;
    a{
        font-size: 1.2rem;
        padding: 1.2rem 2.4rem;
    }
    h1{
        font-size: 3rem;
    }
    img{
        width: 400px;
    }
    p{
        font-size: 1.6rem;
    }
  }
`;

const Error = () => {
  return (
    <ContentErro>
      <Container>
        <Row>
          <Col size={12}>
            <img src={imgErro} alt="Erro 404" />
            <h1>Erro!</h1>
            <p>
              Erro ao carregar o conteúdo, por favor tente novamente mais tarde!
            </p>
            <Link to="/">Voltar para a página inicial</Link>
          </Col>
        </Row>
      </Container>
    </ContentErro>
  );
};

export default Error;
