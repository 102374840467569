import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentSlide }) => `translateX(-${currentSlide * (100 / 3)}%)`};
  width: ${({ slidesToShow }) => slidesToShow * 100}%;
`;

const CarouselSlide = styled.div`
  min-width: calc(100% / ${({ slidesToShow }) => slidesToShow});
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    max-width: 400px;
    height: auto;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  color: #333;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ direction }) => (direction === "left" ? "left: 10px;" : "right: 10px;")}
  z-index: 2;
  outline: none;

  &:hover,
  &:focus {
    color: #3498db;
  }
`;

const CarouselClientes = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide >= slides.length - slidesToShow ? 0 : prevSlide + 1
      );
    }, 3000);

    return () => clearInterval(autoSlide);
  }, [slides.length, slidesToShow]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide >= slides.length - slidesToShow ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - slidesToShow : prevSlide - 1
    );
  };

  return (
    <CarouselContainer aria-label="Carrossel de logos de clientes">
      <ArrowButton
        direction="left"
        onClick={prevSlide}
        aria-label="Slide anterior"
        onKeyDown={(e) => e.key === "Enter" && prevSlide()}
      >
        <FaChevronLeft />
      </ArrowButton>
      <CarouselTrack currentSlide={currentSlide} slidesToShow={slidesToShow}>
        {slides.map((slide, index) => (
          <CarouselSlide
            key={index}
            slidesToShow={slidesToShow}
            role="img"
            aria-label={`Logo Cliente ${index + 1}`}
          >
            <img src={slide} alt={`Logo Cliente ${index + 1}`} />
          </CarouselSlide>
        ))}
      </CarouselTrack>
      <ArrowButton
        direction="right"
        onClick={nextSlide}
        aria-label="Próximo slide"
        onKeyDown={(e) => e.key === "Enter" && nextSlide()}
      >
        <FaChevronRight />
      </ArrowButton>
    </CarouselContainer>
  );
};

export default CarouselClientes;
