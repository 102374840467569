import logoCliente1 from "../../assets/imgs/clientes/pizzaria-sao-jose.png";
import logoCliente2 from "../../assets/imgs/clientes/porto-villares.png";
import logoCliente3 from "../../assets/imgs/clientes/pod-bh.png";
import logoCliente4 from "../../assets/imgs/clientes/cora-e-theo.png";
import logoCliente5 from "../../assets/imgs/clientes/leo-santos.png";
import logoCliente6 from "../../assets/imgs/clientes/GS-PUBLICIDADES.png";
const clientLogos = [
    logoCliente1,
    logoCliente2,
    logoCliente3,
    logoCliente4,
    logoCliente5,
    logoCliente6
];

export{
    clientLogos
}
