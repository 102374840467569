import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./views/Home";
import Contato from "./views/Contato";
import Servicos from "./views/Servicos";
import Error from "./views/Error";
import Velance from "./views/Velance";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="conheca-a-agencia" element={<Velance />} />
        <Route path="servicos" element={<Servicos />} />
        <Route path="contato" element={<Contato />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}

export default App;
