import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import axios from 'axios';
import {
    Container,
    Header,
    ContactGrid,
    FormSection,
    Form,
    FormGroup,
    Button,
    StatusMessage,
    InfoSection,
    ContactInfo,
    Banner,
    BannerText
} from './style';

const Contato = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://apisass.velance.com.br/env-emailvel.php', formData);
      if (response.data.success) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <Banner />  
      <Header>
        <h1>Entre em Contato Conosco</h1>
        <p>Estamos aqui para responder suas dúvidas e ajudar sua empresa a crescer!</p>
      </Header>
      <ContactGrid>
        <FormSection>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <label htmlFor="name">Nome</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </FormGroup>
            <FormGroup>
              <label htmlFor="message">Mensagem</label>
              <textarea name="message" rows="5" value={formData.message} onChange={handleChange} required></textarea>
            </FormGroup>
            <Button type="submit">Enviar</Button>
            {status && <StatusMessage>{status}</StatusMessage>}
          </Form>
        </FormSection>
        <InfoSection>
          <ContactInfo>
            <FaEnvelope />
            <p>velancesolucoes@gmail.com</p>
          </ContactInfo>
          <ContactInfo>
            <FaPhoneAlt />
            <p>(37) 999262829</p>
          </ContactInfo>
          <ContactInfo>
            <FaWhatsapp />
            <a href="https://wa.me/5537999262829" target="_blank" rel="noopener noreferrer">
              Fale conosco no WhatsApp
            </a>
          </ContactInfo>
        </InfoSection>
      </ContactGrid>
    </Container>
  );
};

export default Contato;
