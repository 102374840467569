import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import {
  FooterContainer,
  FooterContent,
  FooterSection,
  SocialIcons,
  Copyright,
} from "./style"; // Ajuste o caminho conforme necessário

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSmoothScroll = (e, targetId, redirectToHome = false) => {
    e.preventDefault();

    const scrollToSection = () => {
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (redirectToHome && location.pathname !== "/") {
      navigate("/", { state: { scrollTo: targetId } });
    } else {
      scrollToSection();
    }
  };

  React.useEffect(() => {
    const { state } = location;
    if (state && state.scrollTo) {
      const targetId = state.scrollTo;
      setTimeout(() => {
        const targetSection = document.getElementById(targetId);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location]);

  return (
    <>
      <FooterContainer>
        <FooterContent>
          {/* Seção de Contato */}
          <FooterSection>
            <h4>Contato</h4>
            <p>Telefone: (37) 99926-2829</p>
            <p>Email: contato@velance.com</p>
          </FooterSection>

          {/* Seção de Endereço */}
          <FooterSection>
            <h4>Endereço</h4>
            <p>Rua Dr Dilermando Alves da Cunha, 64</p>
            <p>Centro, Bambuí - MG</p>
            <p>CEP: 01000-000</p>
            <p>CNPJ: 32.856.456/0001-44</p>
          </FooterSection>

          {/* Seção de Navegação */}
          <FooterSection>
            <h4>Navegação</h4>
            <a href="/" onClick={(e) => handleSmoothScroll(e, "topo", true)}>Home</a>
            <a href="/sobre-nos">Conheça a Velance</a>
            <a href="/" onClick={(e) => handleSmoothScroll(e, "servicos", true)}>Nossos serviços</a>
            <a href="/contato">Contato</a>
          </FooterSection>

          {/* Seção de Redes Sociais */}
          <FooterSection>
            <h4>Redes Sociais</h4>
            <SocialIcons>
              <a href="https://www.facebook.com/atendimentovelance" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://www.instagram.com/velancesolucoes/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAFOercgB7HvVr1ChxEp_nGWA0RgMnf5rIsE&keywords=Velance%20Solu%C3%A7%C3%B5es&origin=ENTITY_SEARCH_HOME_HISTORY&sid=P7c" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </SocialIcons>
          </FooterSection>
        </FooterContent>

        {/* Área de Copyright */}
        <Copyright>
          © {new Date().getFullYear()} Velance - Todos os direitos reservados.
        </Copyright>
      </FooterContainer>
    </>
  );
};

export default Footer;
