import "./assets/css/theme.scss";
import { Outlet } from "react-router-dom";
import Navbar from "./shared/Menu";
import styled from "styled-components";
import Footer from "./shared/Footer";

const Theme = styled.section`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  @media(min-width: 768px) {
    min-height: 70vh;
  }
`;

const Layout = () => {
  return (
    <Theme>
      <Navbar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Theme>
  );
};

export default Layout;
