import styled from 'styled-components';
import imgContato from '../../assets/imgs/contato.png'

export const Container = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Banner = styled.div`
  background-image: url(${imgContato});
  background-size: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-position: center;
  color: #222;
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
  @media (min-width: 768px) {
    height: 80vh;
  }
`;

export const BannerText = styled.div`
  h1 {
    font-size: 3.5rem;
    font-weight: 800;
    margin: 0;
  }

  p {
    font-size: 1.8rem;
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    font-size: 3rem;
    color: #333;
  }

  p {
    font-size: 1.4rem;
    color: #555;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

export const ContactGrid = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  gap: 3rem;
  padding: 4em 0;
  width: 80%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FormSection = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  input, textarea {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }
`;

export const Button = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: white;
  background-color: #F7CF09;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0b807;
  }
`;

export const StatusMessage = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  color: #555;
`;

export const InfoSection = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  color: #555;

  svg {
    font-size: 2rem;
    color: #F7CF09;
  }

  a {
    text-decoration: none;
    color: #555;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }
`;
