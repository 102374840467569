const Servicos = () => {
  return (
    <div>
      <h1>Serviços</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus,
        facere nulla esse, sit delectus eaque saepe similique dolorum amet
        necessitatibus dolores architecto quia nam voluptates excepturi
        molestiae omnis unde hic!
      </p>
    </div>
  );
};

export default Servicos;
